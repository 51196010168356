// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-alphafold-kiosk-index-jsx": () => import("./../../../src/pages/alphafold-kiosk/index.jsx" /* webpackChunkName: "component---src-pages-alphafold-kiosk-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-index-jsx": () => import("./../../../src/pages/press/index.jsx" /* webpackChunkName: "component---src-pages-press-index-jsx" */),
  "component---src-pages-research-index-jsx": () => import("./../../../src/pages/research/index.jsx" /* webpackChunkName: "component---src-pages-research-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../../../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */),
  "component---src-templates-hubspot-form-hubspot-form-jsx": () => import("./../../../src/templates/hubspot-form/HubspotForm.jsx" /* webpackChunkName: "component---src-templates-hubspot-form-hubspot-form-jsx" */),
  "component---src-templates-press-release-press-release-jsx": () => import("./../../../src/templates/press-release/PressRelease.jsx" /* webpackChunkName: "component---src-templates-press-release-press-release-jsx" */),
  "component---src-templates-research-article-research-article-jsx": () => import("./../../../src/templates/research-article/ResearchArticle.jsx" /* webpackChunkName: "component---src-templates-research-article-research-article-jsx" */),
  "component---src-templates-simple-page-simple-page-jsx": () => import("./../../../src/templates/simple-page/SimplePage.jsx" /* webpackChunkName: "component---src-templates-simple-page-simple-page-jsx" */)
}

